import React from "react";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
      <script src="https://f.convertkit.com/ckjs/ck.5.js" />
    </>
  );
};
