import * as React from "react";
import styled from "styled-components";
import "normalize.css";
import GlobalStyles from "../../styles/GlobalStyles";
import { Navigation } from "../Navigation";
import { SubscribeForm } from "../Subscribe";

const Container = styled.div`
  max-width: 800px;
  padding: 12px;
  margin: 0 auto 48px;
  text-align: center;
`;

const MarginSubscribeForm = styled(SubscribeForm)`
  outline: 3px solid red;
`;

const CategoryHeading = styled.div`
  margin: 100px 20px;
  text-align: center;
`;

const CategoryDescription = styled.p`
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin: 0px auto;
`;

export const CategoryLayout = ({ children, heading }) => {
  return (
    <>
      <GlobalStyles />
      <Navigation />
      <CategoryHeading>
        <h1>{heading}</h1>
        <CategoryDescription>
          Want to know what you'll learn as a subscriber?
          <br />
          While most of the content is exclusive to the newsletter, here are a
          couple of posts for you to sample.
        </CategoryDescription>
      </CategoryHeading>
      <Container>
        {children}
        <MarginSubscribeForm />
      </Container>
    </>
  );
};

export default CategoryLayout;
