import { createGlobalStyle } from "styled-components";

import GilroyBold from "../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../assets/fonts/gilroy/Gilroy-Medium.ttf";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Gilroy";
    src: url(${GilroyBold});
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Gilroy";
    src: url(${GilroyMedium});
    font-weight: normal;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  body {
    --bg: rgb(19, 21, 21);
    --bg-transparent: rgba(255, 255, 255, 0.1);
    --text: rgb(255, 250, 251);
    --highlight: rgb(249, 191, 59);
    --btn: rgb(29, 66, 137);
    --btn-text: rgb(255,250, 251);
    --accent: rgb(0, 0, 0, 0);
    --text-highlight: rgb(76, 201, 85);


    --lightblue: rgb(218, 223, 225);
    --green: rgb(74, 221, 140);
    --pink: rgb(255, 0, 255);
  
    --orange: rgb(242, 100, 48);
    --red: rgb(211, 78, 36);
    --purple: rgb(167, 128, 253);
    
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    background: var(--bg);
    color: var(--text);
    cursor: default;
    transition: color 0.2s ease-out 0s, background 0.2s ease-out 0s
  }

  body.light {
    --bg: rgb(255, 255, 255);
    --bg-transparent: rgba(29, 66, 137, 0.1);
    --text: rgb(19, 21, 21);
    --highlight: rgb(29, 66, 137);
    --btn: rgb(29, 66, 137);
    --btn-text: rgb(255,250, 251);
    --text-highlight: rgb(166,226,46);
  }

  h1 {
    font-size: calc(3.5rem);
    position: relative;
  }

  h2 { 
    font-size: 2rem;
    padding-top: 60px;
    margin-top: 0;
    margin-bottom: 32px;
    position: relative;

    @media(max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h3, h4, h5, h6 {
    position: relative;
  }

  p, b, span, i, em, ul, ol, li {
    font-size: 1.2rem;
    line-height: 1.625rem;

    @media(max-width: 768px) {
      font-size: 1rem;
    }
  }

  button span {
    line-height: inherit;
  }

  ul, ol {
    padding-left: 1.5rem;
  }

  ol li {
    padding-left: 4px;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: var(--highlight);
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
    width: fit-content;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 2px solid var(--highlight);
      outline-offset: 4px;
      text-decoration: none;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  hr {
    margin: 4rem 0;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .toggle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-bottom: 1em;
    cursor: pointer;
    gap: 1ch;
  }

  .toggle__input {
    position: absolute;
    opacity: 0;
  }
  
  .toggle__display {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: content-box;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1rem;
    position: relative;
    background: var(--highlight);
    transition: 250ms;
  }
  
  .toggle__display::before {
    content: "";
    z-index: 2;
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 2px solid var(--accent);
    background: white;
    transform: translate(-60%, -50%);
    will-change: transform;
    transition: inherit;
  }
  
  
  .toggle:focus .toggle__display,
  .toggle__input:focus + .toggle__display {
    outline: 3px solid var(--highlight);
    outline-offset: 2px;
  }
  
  .toggle:focus,
  .toggle:focus:not(:focus-visible) .toggle__display,
  .toggle__input:focus:not(:focus-visible) + .toggle__display {
    outline: 0;
  }
  
  .toggle[aria-pressed="true"] .toggle__display,
  .toggle__input:checked + .toggle__display {
    background-color: var(--highlight);
  }
  
  .toggle[aria-pressed="true"] .toggle__display::before,
  .toggle__input:checked + .toggle__display::before {
    transform: translate(60%, -50%);
  }
`;

export default GlobalStyles;
