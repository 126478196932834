import * as React from "react";
import styled from "styled-components";

const SettingsContainer = styled.div`
  position: fixed;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  width: 60px;
  background: var(--highlight);
  color: var(--bg);
`;

const Button = styled.button`
  border: 1px solid black;
  background: transparent;
  min-height: 60px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const A11ySettings = ({}) => {
  return (
    <SettingsContainer>
      <Button onClick={() => alert("Up")}>Up</Button>
      <Button onClick={() => alert("Down")}>Down</Button>
      <Button onClick={() => alert("Dark")}>Dark </Button>
    </SettingsContainer>
  );
};

export default A11ySettings;
