import * as React from "react";
import styled from "styled-components";
import Highlight, { defaultProps } from "prism-react-renderer";
import vsDark from "prism-react-renderer/themes/vsDark";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
/* https://levelup.gitconnected.com/syntax-highlighting-in-gatsby-mdx-f0187ce51f4f */
/* https://www.npmjs.com/package/prism-react-renderer?activeTab=readme#theming */

export const CustomPre = styled.pre`
  padding: 10px;
  text-align: left;
  font-size: 0.9em;
  margin-bottom: 60px;
  overflow: scroll;
`;

export const LiveCode = (props) => {
  let disabled = false;
  if (props.children.props["disabled"]) {
    disabled = true;
  }
  return (
    <LiveProvider
      code={props.children.props.children.trim()}
      theme={vsDark}
      disabled={disabled}
    >
      <LiveEditor />
      <LiveError />
      <LivePreview />
    </LiveProvider>
  );
};

export const SyntaxHighlighter = (props) => {
  console.log(props);
  const className = props.children.props.className || "";
  const matches = className.match(/language-(?<lang>.*)/);
  return (
    <Highlight
      {...defaultProps}
      code={props.children.props.children.trim()}
      language={
        matches && matches.groups && matches.groups.lang
          ? matches.groups.lang
          : ""
      }
      theme={vsDark}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <CustomPre className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </CustomPre>
      )}
    </Highlight>
  );
};

const NewWindowWrapper = styled.a`
  svg {
    vertical-align: text-top;
    margin-left: 2px;
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const NewWindow = (props) => {
  return (
    <NewWindowWrapper {...props}>
      {props.children}
      {!props.internal && (
        <>
          <span class="sr-only">This link opens in a new tab</span>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 13C12.5523 13 13 12.5523 13 12V3C13 2.44771 12.5523 2 12 2H3C2.44771 2 2 2.44771 2 3V6.5C2 6.77614 2.22386 7 2.5 7C2.77614 7 3 6.77614 3 6.5V3H12V12H8.5C8.22386 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12ZM9 6.5C9 6.5001 9 6.50021 9 6.50031V6.50035V9.5C9 9.77614 8.77614 10 8.5 10C8.22386 10 8 9.77614 8 9.5V7.70711L2.85355 12.8536C2.65829 13.0488 2.34171 13.0488 2.14645 12.8536C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L7.29289 7H5.5C5.22386 7 5 6.77614 5 6.5C5 6.22386 5.22386 6 5.5 6H8.5C8.56779 6 8.63244 6.01349 8.69139 6.03794C8.74949 6.06198 8.80398 6.09744 8.85143 6.14433C8.94251 6.23434 8.9992 6.35909 8.99999 6.49708L8.99999 6.49738"
              fill="currentColor"
            />
          </svg>
        </>
      )}
    </NewWindowWrapper>
  );
};

export const Anchor = styled.a`
  position: absolute;
  left: -35px;
`;

export const StyledCode = styled.code`
  position: relative;
  display: inline;
  font-size: 0.9em;
  letter-spacing: -0.5px;
  padding: 4px 6px 5px 6px;
  margin: 1px 0;
  background: var(--bg-transparent);
  border-radius: 3px;
  -webkit-box-decoration-break: clone;
`;
