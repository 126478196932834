import * as React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

const BreadcrumbList = styled.ol`
  list-style: none;
  margin-bottom: 1rem;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
`;

const BreadcrumbItem = styled.li``;

const Crumb = styled(Link)`
  text-transform: capitalize;

  &:after {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #464646;
    font-weight: normal;
    content: " / ";
  }

  ${(p) =>
    p.last &&
    css`
      color: var(--highlight);

      &:after {
        display: none;
      }
    `}
`;

export const Breadcrumb = (props) => {
  const { slug } = props;
  let before = slug.split("/");
  before =
    before[before.length - 1] === ""
      ? before.slice(0, before.length - 1)
      : before;
  let crumbs = before;

  function isLast(index) {
    return index === crumbs.length - 1;
  }

  return (
    <nav>
      <BreadcrumbList>
        {crumbs.map((crumb, i) => {
          let url = "/" + crumbs.slice(0, i + 1).join("/");

          return (
            <BreadcrumbItem>
              <Crumb
                to={url}
                last={isLast(i)}
                aria-current={isLast(i) ? "page" : undefined}
              >
                {crumb}
              </Crumb>
            </BreadcrumbItem>
          );
        })}
      </BreadcrumbList>
    </nav>
  );
};

export default Breadcrumb;
