import * as React from "react";
import styled from "styled-components";
import "normalize.css";
import GlobalStyles from "../../styles/GlobalStyles";
import { Navigation } from "../Navigation";
import { SubscribeForm } from "../Subscribe";

const Container = styled.div`
  max-width: 675px;
  padding: 12px;
  margin: 0 auto 48px;

  h1 {
    margin-top: 3rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 12px 30px;
  }
`;

const MarginSubscribeForm = styled.div`
  margin: 4rem 0;
`;

const SubscribeCTA = styled.p`
  font-size: 1.2rem;
  margin-bottom: 4rem;
  text-align: center;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;

  a {
    margin: 0 0.5rem;
  }

  .social-icon {
    height: 1.5rem;
  }

  a#coffee-link {
    padding-top: 2px;
  }
`;

export const ArticleLayout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Navigation />
      <Container>
        {children}
        <hr />
        <MarginSubscribeForm>
          <SocialLinks>
            <a
              href="https://twitter.com/intent/tweet?text=I%20just%20signed%20up%20for%20this%20awesome%20accessibility%20course%20%F0%9F%99%8C&url=https://a11yexamples.com/?ref=twitter&via=CormacChisholm&hashtags=a11y,accessibility,wcag"
              target="_blank"
              rel="noopener noreferrer"
              title="Share on twitter "
            >
              <svg
                fill="currentColor"
                viewBox="0 0 30 30"
                width="30px"
                height="30px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
              </svg>
              <span className="sr-only">
                Share on twitter, this link will open in a new tab
              </span>
            </a>
            <a
              id="coffee-link"
              href="https://www.buymeacoffee.com/a11y"
              target="_blank"
              rel="noopener noreferrer"
              title="Buy me a coffee"
            >
              <svg
                fill="currentColor"
                height="28px"
                width="28px"
                viewBox="-18 -114 573.33919 573"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m446.4375 43.292969c-2.339844-.421875-4.703125-.742188-7.078125-.996094.847656-22.378906-.074219-36.105469-.148437-37.132813-.457032-6.445312-5.832032-11.445312-12.300782-11.425781h-414.128906c-6.464844-.019531-11.84375 4.980469-12.300781 11.425781-.097657 1.949219-3.160157 47.09375 7.527343 110.207032 9.839844 58.335937 33.964844 143.480468 92.539063 217.820312 2.332031 2.964844 5.898437 4.691406 9.667969 4.6875h219.300781c3.769531 0 7.324219-1.730468 9.652344-4.6875 17.160156-21.875 32.121093-45.382812 44.660156-70.195312h43.386719c57.246094-.226563 104.875-44.050782 109.863281-101.070313 4.988281-57.023437-34.308594-108.445312-90.640625-118.609375zm-17.410156 182.574219h-28.292969c14.25-35.617188 24.632813-72.664063 30.972656-110.496094 2.191407-12.90625 3.800781-25.046875 4.984375-36.246094 38.957032 4.046875 67.90625 37.902344 65.867188 77.011719-2.046875 39.109375-34.371094 69.765625-73.53125 69.730469zm0 0" />
              </svg>
              <span className="sr-only">
                Buy me a coffee, this link will open in a new tab
              </span>
            </a>
          </SocialLinks>
          <SubscribeCTA>
            Enjoyed this post? <br />
            <br />
            Make sure to <b>subscribe</b> so you don't miss out on the next one
          </SubscribeCTA>
          <SubscribeForm />
        </MarginSubscribeForm>
      </Container>
    </>
  );
};

export default ArticleLayout;
