import React from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";

//https://kittygiraudel.com/2021/04/05/an-accessible-toggle/

export const ThemeSwitch = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <>
          <label htmlFor="toggle">
            <input
              type="checkbox"
              name="toggle"
              id="toggle"
              className="toggle__input"
              onChange={(e) => toggleTheme(e.target.checked ? "light" : "dark")}
              checked={theme === "light"}
              aria-label="Light Mode Enabled"
            />
            <span className="toggle__display" hidden>
              <svg
                width="18"
                height="18"
                viewBox="0 0 249 250"
                fill="#f9bf3b"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                className="toggle__icon toggle__icon--sun"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M102.435 37.1165L121.588 2.64142C123.094 -0.0690094 126.977 -0.109741 128.54 2.56851L148.534 36.8441C141.332 34.9875 133.781 34 126 34C117.846 34 109.946 35.0843 102.435 37.1165ZM80.1097 46.5622L41.2668 36.2897C38.2747 35.4984 35.556 38.2593 36.3933 41.2389L47.3469 80.2208C55.1031 66.3159 66.4397 54.6807 80.1097 46.5622ZM38.7488 101.839L3.01656 121.005C0.215324 122.507 0.201221 126.519 2.99182 128.041L39.1742 147.777C37.1048 140.203 36 132.23 36 124C36 116.353 36.9538 108.928 38.7488 101.839ZM47.5952 168.222L36.0411 208.826C35.1676 211.896 38.0647 214.702 41.1052 213.731L79.9328 201.332C66.4869 193.305 55.3104 181.871 47.5952 168.222ZM100.736 210.406L120.591 247.185C122.079 249.942 126.016 249.995 127.578 247.28L148.326 211.209C141.187 213.031 133.707 214 126 214C117.229 214 108.751 212.745 100.736 210.406ZM171.321 201.773L208.631 213.788C211.622 214.751 214.504 212.049 213.736 209.003L203.746 169.366C195.914 182.76 184.72 193.948 171.321 201.773ZM212.996 147.143L246.798 128.01C249.515 126.472 249.501 122.553 246.773 121.034L213.407 102.462C215.101 109.363 216 116.577 216 124C216 132.001 214.956 139.759 212.996 147.143ZM204.056 79.1672L213.796 41.1501C214.557 38.181 211.832 35.4946 208.874 36.2968L171.617 46.4005C185.062 54.3211 196.267 65.6361 204.056 79.1672Z"
                />
                <circle cx="126" cy="124" r="80" />
              </svg>
              <svg
                width="18"
                height="14"
                viewBox="0 0 253 259"
                fill="#131515"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                className="toggle__icon toggle__icon--moon"
              >
                <path d="M251.577 171.253C254.084 164.418 246.156 158.853 239.58 161.977C224.705 169.045 208.065 173 190.5 173C162.051 173 133.525 163.173 113.5 146C89.0137 125 76 93.2875 76 58.5001C76 42.2749 79.3748 26.838 85.4608 12.853C88.4445 5.99663 82.2756 -1.88371 75.5297 1.34188C30.9397 22.6629 0 69.3514 0 123.517C0 197.809 58.203 258.035 130 258.035C185.551 258.035 232.964 221.981 251.577 171.253Z" />
              </svg>
            </span>{" "}
          </label>
        </>
      )}
    </ThemeToggler>
  );
};
