import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";

const TagContent = styled.span`
  padding: 10px;
  color: black;
  border-radius: 4px;
  background: white;
`;

export const Tag = (props) => {
  const { text } = props;

  return (
    <Link to={"/tag/" + text}>
      <TagContent>{text}</TagContent>
    </Link>
  );
};

export default Tag;
