import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  max-width: 675px;
  padding: 12px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 12px 30px;
  }
`;

const Copyright = styled.p`
  font-size: 0.875rem;
  text-align: center;
`;

export const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <StyledFooter>
      <Copyright>© {year} Cormac Chisholm. All Rights Reserved.</Copyright>
    </StyledFooter>
  );
};
