import * as React from "react";
import styled, { css } from "styled-components";

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  background: var(--bg-transparent);
  border-radius: 4px;
  max-width: 360px;
  margin: 0 auto;
`;

const inputStyles = css`
  border-radius: 6px;
  height: calc(30px + 1rem);
  width: 100%;
  padding: 15px;
  margin: 8px auto;
  border: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 3px 3px var(--highlight);
  }
`;

const Email = styled.input`
  ${inputStyles}
  box-sizing: border-box;
  border: 1px solid var(--text);
`;

const SubscribeButton = styled.button`
  ${inputStyles}
  background: var(--btn);
  color: var(--btn-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Gilroy", sans-serif;
  span {
    font-size: 1rem;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const Fieldset = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 100%;
`;

const Formkit = styled.div`
  text-align: left;
`;

const Label = styled.label`
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;
  font-size: 1rem;

  span {
    color: var(--red);
  }
`;

const SmallText = styled.small`
  margin: 10px auto 0;
`;

export const SubscribeForm = () => (
  <EmailForm
    action="https://app.convertkit.com/forms/2992274/subscriptions"
    className="seva-form formkit-form"
    method="post"
    data-sv-form="2992274"
    data-uid="2825a1eaaa"
    data-format="inline"
    data-version="5"
    data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
  >
    <div data-style="clean">
      <ul
        className="formkit-alert formkit-alert-error"
        data-element="errors"
        data-group="alert"
      ></ul>
      <Fieldset
        data-element="fields"
        data-stacked="false"
        className="seva-fields formkit-fields"
      >
        <Formkit className="formkit-field">
          <Label htmlFor="subscriber-email">Your email address</Label>
          <Email
            className="formkit-input"
            name="email_address"
            placeholder="e.g. johnsmith@gmail.com"
            required
            type="email"
            id={
              "subscriber-email" + (Math.random() + 1).toString(36).substring(7)
            }
          />
        </Formkit>
        <SubscribeButton
          data-element="submit"
          className="formkit-submit formkit-submit"
        >
          <div className="formkit-spinner">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <span className="">Get the course</span>
        </SubscribeButton>
      </Fieldset>
      <SmallText>No spam. Unsubscribe at any time.</SmallText>
    </div>
  </EmailForm>
);

export default SubscribeForm;
