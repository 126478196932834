import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ThemeSwitch } from "../../components";

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
`;

const Header = styled.header`
  height: 60px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BrandNav = styled.nav`
  font-size: 20px;
  a {
    color: var(--highlight);
  }
`;

const Link = styled(GatsbyLink)`
  padding: 10px;
  color: var(--text);
  text-decoration: none;
`;

export const Navigation = () => {
  return (
    <Container>
      <Header>
        <BrandNav>
          <Link to="/">
            <span>a11y examples</span>
          </Link>
        </BrandNav>
        <ThemeSwitch />
      </Header>
    </Container>
  );
};
